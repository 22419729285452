<template>
  <div class="image-loader-wrapper">
    <div v-if="label" class="caption mb-1">{{ label }}</div>
    <div class="image-uploader">
      <v-img
        ref="image"
        :src="src || '/img/media.jpg'"
        alt="Select media file"
        height="200"
        width="100%"
        @load="src && $emit('load', $refs.image.image)"
      />
      <div class="upload">
        <v-btn text dense class="shadow" small fab @click="triggerUpload">
          <input
            ref="photo"
            type="file"
            accept="image/*"
            style="display: none"
            @change="$emit('change', $event)"
          />
          <v-icon color="white">
            mdi-cloud-upload
          </v-icon>
        </v-btn>
        <v-btn
          v-if="src"
          text
          dense
          color="transparent"
          class="ml-3 shadow"
          small
          fab
          type="reset"
          @click="$emit('clear')"
        >
          <v-icon color="white">
            mdi-delete
          </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageUploader',
  props: {
    label: {
      type: String,
      default: ''
    },
    src: {
      type: String,
      default: ''
    }
  },
  setup(props, { refs }) {
    const triggerUpload = () => {
      refs.photo.click();
    };
    return {
      triggerUpload
    };
  }
};
</script>

<style lang="sass" scoped>
.image-uploader
    position: relative
    border: thin solid rgba(0,0,0,0.3)
.user_photo
  position: relative
  background-color: #dbdbdb
  background-position: center
  min-height: 120px

.upload
  position: absolute
  top: 15px
  right: 15px

.shadow
  background-color: rgba(0, 0, 0, 0.44) !important
  border-color: rgba(0, 0, 0, 0.44) !important
</style>
